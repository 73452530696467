<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addVideo'})">
      {{ $t('auth.videos') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="4" class="mb-3">
            <input-form placeholder="العنوان" label="العنوان" v-model="videoData.title" name="title" validate="required"></input-form>
          </b-col>
          <b-col md="9" class="mb-3">
            <textarea-form  placeholder="المحتوى" v-model="videoData.content" label="التفاصيل"  name="description" validate="required"></textarea-form>
          </b-col>
          <b-col md="9" class="mb-3">
            <label class="text-black font-size-18">تاريخ النشر</label>
            <flat-picker  v-model="videoData.publish_date" class="form-control form-date"  validate="required" name="publishDate" :config="{nowDate: 'today'}" placeholder="تاريخ النشر"></flat-picker>
          </b-col>
          <b-col md="9" class="my-3">
<!--            <video controls="controls" :src="video" type="video/mp4" class="w-100 h-250px"></video>-->
            <div class="userProfile-img-wrapper d-flex justify-content-center align-items-center mb-5 imgDiv position-relative">
              <input type="file" class="d-none" :id="`profile-uploader-user-profile`" :name="`file-uploader-profile-uploader-user-profile${!loading ? '-dispabled' : ''}`" @change="addFile"/>
              <label class="userProfile-img-label m-0 w-100 h-100 position-relative" :for="`profile-uploader-user-profile`">
                <iframe style="width: 100%" height="200"
                        :src="videoData.featured_image" class="userProfile-img"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<!--              <video controls="controls" type="video/mp4  ">{{videoData.featured_image}}</video>-->
                <div class="position-absolute align-items-center justify-content-center editUserImg" v-if="!loading">
                  <span class="text-white font-size-16">upload Video <i class="las la-upload font-weight-bold"></i></span>
                </div>
                <div class="editUserImg loadingEditImg position-absolute align-items-center justify-content-center" v-else>
                  <b-spinner label="Spinning" variant="primary"></b-spinner>
                </div>
              </label>
            </div>
          </b-col>
      </b-row>
        <b-row>
          <b-col md="9" class="d-flex justify-content-center align-items-center">
            <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.gallery') }}</b-button>
            <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
          </b-col>
        </b-row>
<!--                <div class="d-flex align-items-center justify-content-center ">-->
<!--                  <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.events') }}</b-button>-->
<!--                  <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>-->
<!--                </div>-->
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import eventsServices from '../services/videos'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: { },
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      video: '',
      loading: false,
      gallery: {
        title: '',
        description: '',
        url: ''
      },
      showFaqInput: false,
      loadingSubmit: false,
      loadingLogo: 0,
      loadingGallery: 0,
      removeLoadingUi: false,
      videoData: {
        featured_image: '',
        title: '',
        content: '',
        publish_date: ''
      }
    }
  },
  methods: {
    addFile (e) {
      this.loading = true
      const targetFile = e.target.files[0]
      const formData = new FormData()
      formData.append('file', targetFile)
      formData.append('directory', 'videos')
      formData.append('app_name', 'website')
      eventsServices.uploadVideo(formData).then(res => {
        this.videoData.featured_image = res.data.url
      }).finally(() => {
        this.loading = false
      })
    },
    getVideoData () {
      eventsServices.getVideoDetails(this.$route.params.id).then(res => {
        this.videoData = res.data
      })
    },
    onSubmit () {
      if (this.$route.name === 'editVideo') {
        this.editVideo()
      } else {
        this.loadingSubmit = true
        eventsServices.addVideo({
          gallery: this.videoData,
          gallery_media: [
            {
              title: this.videoData.title,
              content: this.videoData.content,
              url: this.videoData.featured_image
            }
          ]
        }).then(res => {
          core.showSnackbar('success', 'تم اضافة ')
          this.loadingSubmit = false
          this.$router.push({ name: 'videos' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    },
    editVideo () {
      eventsServices.editVideo(this.$route.params.id, this.imageData).then(() => {
        core.showSnackbar('success', 'تم تعديل بنجاح')
        this.$router.push({ name: 'videos' })
      })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getVideoData()
    }
  }
}
</script>

<style>
.userProfile-img-wrapper {
  cursor: pointer;

  margin: auto;
  overflow: hidden;
}
.userProfile-img{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-color: #d2cece;
  background-repeat: no-repeat;
}
.editUserImg{
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(115, 115, 115, 0.8);
  /*display: none;*/
}
</style>
